import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Social Gallery</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
        // hero={
        //   <HeroElement
        //     header={"Follow us @EggNBird"}
        //     tagline={""}
        //     images={[data.heroImages.map(({ url }) => url)[0]]}
        //     showMap={false}
        //     ctas={[]}
        //     ctaInverted={true}
        //     ctaColor={"white"}
        //     containerColor={"primary"}
        //     textColor={"white"}
        //     showMultiple={true}
        //     overlay={true}
        //     fullWidth={true}
        //     parallax={true}
        //     containerAs={"none"}
        //     height={500}
        //     gutter={false}
        //   />
        // }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <div>
                <h1
                  style={{
                    fontSize: "3.5rem",
                    color: "#fff",
                    marginBottom: "1em",
                  }}
                >
                  Follow us @EggNBird
                </h1>
              </div>

              <iframe
                src="https://cdn.lightwidget.com/widgets/b5cd119c9c295cf9977f29a4af3a9dd3.html"
                scrolling="yes"
                allowtransparency="true"
                class="lightwidget-widget"
                style={{
                  width: "100%",
                  border: "0",
                  overflow: "hidden",
                  height: "600px",
                }}
              ></iframe>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
